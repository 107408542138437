// Bootstrap Overrides
@import "./base/bootstrap_overrides.module";


// Bootstrap
@import "./vendor/bootstrap";

// Form styling
[data-required="true"] .form-label:after {
    content:"*";
    color: $red;
    
}